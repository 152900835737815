import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  tokenLoading: false,
  userLoading: false,
  codeSending: false,
  codeGetting: false,
  user: null,
  isAdmin: false,
  isEmployee: false,
  isAuthorized: false,
  authToken: '',
  showStartInfoTooltip: true,
  ymetrika: true,
  session: null,
  settings: null,
  checkCodeType: null,
  checkCodeLogin: null,
  lastError: null,
  error: null,
  endPointToken: '',
  endPointName: '',
  endPointSurname: '',
  endPointAvatar: '',
  isEndPoint: false,
};

export const callTypes = {
  register: 'register',
  auth_password: 'auth_password',
  getUser: 'getUser',
  getUser: 'endPointLogin',
  getCode: 'getCode',
  sendCode: 'sendCode',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (action.payload.callType === callTypes.auth_password) {
        state.tokenLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.register) {
        state.tokenLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.getUser) {
        state.userLoading = false;
        state.codeSending = false;
        state.authToken = initialAuthState.authToken;
        state.user = initialAuthState.user;
      }

      if (action.payload.callType === callTypes.getCode) {
        state.codeGetting = false;
      }

      if (action.payload.callType === callTypes.sendCode) {
        state.codeSending = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.auth_password) {
        state.tokenLoading = true;
      }
      if (action.payload.callType === callTypes.register) {
        state.tokenLoading = true;
      }

      if (action.payload.callType === callTypes.getUser) {
        state.userLoading = true;
      }

      if (action.payload.callType === callTypes.getCode) {
        state.codeGetting = true;
      }

      if (action.payload.callType === callTypes.sendCode) {
        state.codeSending = true;
      }
    },

    registerFetched: (state, action) => {
      const { access_token } = action.payload;
      state.authToken = access_token;
      state.tokenLoading = false;
      state.showStartInfoTooltip = true;
    },

    endPointFetched: (state, action) => {
      const { name, surname, avatar, access_token } = action.payload;
      state.endPointToken = access_token;
      state.endPointName = name;
      state.endPointSurname = surname;
      state.endPointAvatar = avatar;
      state.isEndPoint = true;
      state.tokenLoading = true;
      state.showStartInfoTooltip = true;
    },

    endPointLogin: (state, action) => {
      state.authToken = action.payload;
      state.tokenLoading = false;
    },

    disableStartInfoTooltip: state => {
      state.showStartInfoTooltip = false;
    },

    tokenFetched: (state, action) => {
      const { access_token } = action.payload;
      state.authToken = access_token;
      state.tokenLoading = false;
      state.showStartInfoTooltip = true;
    },

    userFetched: (state, action) => {
      state.isAdmin = action.payload.isAdmin;
      state.ymetrika = !action.payload.isAdmin;
      state.isEmployee = action.payload.isEmployee;
      state.user = action.payload;
      state.userLoading = false;
      state.isAuthorized = true;
      state.session = null;
      state.checkCodeType = null;
      state.checkCodeLogin = null;
    },

    logoutUser: state => {
      Object.keys(state).map(stateItem => (state[stateItem] = initialAuthState[stateItem]));
    },

    CheckCodeFetched: (state, action) => {
      const { send_type, session, login } = action.payload;
      state.checkCodeType = send_type;
      state.checkCodeLogin = login;
      state.codeGetting = false;
      state.session = session;
      state.tokenLoading = false;
    },

    setUserData: (state, action) => {
      const user = action.payload;
      state.user = { ...user };
      state.isAdmin = user.isAdmin;
      state.isEmployee = user.isEmployee;
    },

    clearCheckCode: state => {
      state.checkCodeType = initialAuthState.checkCodeType;
      state.checkCodeLogin = initialAuthState.checkCodeLogin;
      state.session = initialAuthState.session;
      state.error = initialAuthState.session;
    },

    codeSended: (state, action) => {
      const { token } = action.payload;
      state.authToken = token;
      state.codeSending = false;
      state.showStartInfoTooltip = true;
    },
  },
});
